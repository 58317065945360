// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-js": () => import("./../../../src/pages/all.js" /* webpackChunkName: "component---src-pages-all-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-guidelines-js": () => import("./../../../src/pages/guidelines.js" /* webpackChunkName: "component---src-pages-guidelines-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-majors-js": () => import("./../../../src/pages/majors.js" /* webpackChunkName: "component---src-pages-majors-js" */),
  "component---src-pages-newpage-js": () => import("./../../../src/pages/newpage.js" /* webpackChunkName: "component---src-pages-newpage-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-trail-js": () => import("./../../../src/pages/trail.js" /* webpackChunkName: "component---src-pages-trail-js" */),
  "component---src-pages-why-js": () => import("./../../../src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */),
  "component---src-pages-years-js": () => import("./../../../src/pages/years.js" /* webpackChunkName: "component---src-pages-years-js" */),
  "component---src-templates-author-template-js": () => import("./../../../src/templates/authorTemplate.js" /* webpackChunkName: "component---src-templates-author-template-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/categoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-major-template-js": () => import("./../../../src/templates/majorTemplate.js" /* webpackChunkName: "component---src-templates-major-template-js" */),
  "component---src-templates-portfolio-item-jsx": () => import("./../../../src/templates/portfolio-item.jsx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/tagsTemplate.js" /* webpackChunkName: "component---src-templates-tags-template-js" */),
  "component---src-templates-year-template-js": () => import("./../../../src/templates/yearTemplate.js" /* webpackChunkName: "component---src-templates-year-template-js" */)
}

